// 评价审核
<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
export default {
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/pc/settlement/fund/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          type: 'input',
          key: 'shopName'
        },
        {
          name: '收款人',
          type: 'input',
          key: 'accountUserName'
        },
        {
          name: 'ID',
          type: 'input',
          key: 'id'
        },
        {
          name: '银行账号',
          type: 'input',
          key: 'accountNo'
        },
        {
          name: '结算金额',
          type: 'input',
          key: 'jsMoney'
        }
      ]
    },
    getColumns() {
      let that = this
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'shopName',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.shopName.length - b.shopName.length
        },
        {
          dataIndex: 'accountUserName',
          title: '收款人',
          align: 'left'
        },
        {
          dataIndex: 'accountNo',
          title: '银行账号',
          align: 'left'
        },
        {
          dataIndex: 'accountName',
          title: '银行信息',
          align: 'left'
        },
        {
          dataIndex: 'jsMoney',
          title: '结算金额',
          align: 'left',
          sorter: (a, b) => a.jsMoney - b.jsMoney
        },
        {
          dataIndex: 'jsStatus',
          title: '结算类型',
          type: 'badge',
          onExport: records => {
            return {
              '0': '未结算',
              '1': '已结算',
              '-1': '结算失败'
            }[records]
          },
          filters: [
            {
              text: '结算失败',
              value: '-1'
            },
            {
              text: '未结算',
              value: '0'
            },
            {
              text: '已结算',
              value: '1'
            }
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: {
                '0': '未结算',
                '1': '已结算',
                '-1': '结算失败'
              }[data],
              color: {
                '0': 'orange',
                '1': 'green',
                '-1': 'red'
              }[data]
            }
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '拒绝',
                type: 'pop',
                display: records.jsStatus == '0',
                popTitle: '确认拒绝结算吗?',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/api/pc/settlement/fund/refused?id=${records.id}`
                  })
                }
              },
              {
                name: '同意',
                type: 'pop',
                display: records.jsStatus == '0',
                popTitle: '确认同意结算吗?',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/api/pc/settlement/fund/agree?id=${records.id}`
                  })
                }
              }
            ].filter(e => e.display)
          }
        }
      ]
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                // let idList = data.map(e => e.id)
                // api.command.edit.call(this, {
                //   url: '/base/farmEvaluate/deleteBatch',
                //   params: { idList }
                // })
              }
            }
          ]
        }
      ]
    },
    getDrawerForm(records) {
      const form = [
        {
          title: '基础信息',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '时间',
                  value: records.createDate
                },
                {
                  name: '评论人',
                  value: records.nickname
                },
                {
                  name: '评论来源',
                  value: records.relationType
                }
              ]
            }
          ]
        },
        {
          title: '内容',
          form: [
            {
              type: 'text',
              value: records.content
            }
          ]
        },
        {
          title: '图片',
          form: [
            {
              type: 'imageGroup',
              value: records.img
            }
          ]
        }
      ]
      return form
    },
    onRowSelect(records) {
      const data = this.getDrawerForm(records)
      apiTool.showDrawer({
        title: '详情',
        width: '700px',
        view: DrawerForm,
        viewProps: { data, form: records }
      })
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        onSelectRow={this.onRowSelect}
        records={this.records}
      />
    )
  }
}
</script>
